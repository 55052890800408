import React, {useRef, useState, useEffect} from "react"
import * as styles from "./styles.module.scss"
import Logo from "../../assets/Logo";
import {Link} from "gatsby";
import Hamburger from "./Hamburger";
import NavBar from "./NavBar";
import {scrollToTop} from "../../helpers/ScrollTop";

const Header = () => {
    const [isActive, setIsActive] = useState(false)
    const some = useRef(null)
    const [scrollPosition, setScrollPosition] = useState(0);

    const handleScrollPosition = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScrollPosition);
        return () => {
            window.removeEventListener("scroll", handleScrollPosition);
        }
    }, [])
    const handleClick = () => {
        setIsActive(prev => !prev)
    }
    const handleScroll = () => {
        scrollToTop()
    }
    const hiddeModal = () =>{
        if(isActive === true){
            setIsActive(prev => !prev)
        }
    }

    return (
        <header className={`${styles.header} ${isActive? styles.header_active : ''} ${scrollPosition !== 0 ? styles.header_bg : ''}` }>
            <div className={styles.header_top}>
                <Link onClick={handleScroll} className={styles.header_logo} to={'/'}><Logo/></Link>
                <div className={styles.header_navBar}>
                    <NavBar hideModal={hiddeModal}/>
                </div>
                <div onClick={handleClick} className={`${styles.header_hamburger}` }>
                    <Hamburger/>
                </div>
            </div>

            <div ref={some} className={styles.header_wraper__mobileMenu}  >
                <div className={styles.header_mobileMenu}>
                    <NavBar hideModal={hiddeModal}/>
                </div>
            </div>
        </header>
    )
}

export default Header
