import React from "react"
import Header from "../components/Header/Header";
import * as styles from "./styles.module.scss"
import CookiesModal from "../components/CookiesModal/CookiesModal";
import StateProvider from "../context/store";


const Layout = ({children}) => {
    return (
        <StateProvider>
            <div className={styles.layout}>
                <div className={styles.layout_header}>
                    <Header/>
                </div>
                <div className={styles.layout_content}>
                    {children}
                </div>
                <CookiesModal/>
            </div>
        </StateProvider>
    )
}
export default Layout