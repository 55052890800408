exports.components = {
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-contacts-index-js": () => import("./../../../src/pages/contacts/index.js" /* webpackChunkName: "component---src-pages-contacts-index-js" */),
  "component---src-pages-errors-404-js": () => import("./../../../src/pages/errors/404.js" /* webpackChunkName: "component---src-pages-errors-404-js" */),
  "component---src-pages-event-index-jsx": () => import("./../../../src/pages/event/index.jsx" /* webpackChunkName: "component---src-pages-event-index-jsx" */),
  "component---src-pages-events-index-js": () => import("./../../../src/pages/events/index.js" /* webpackChunkName: "component---src-pages-events-index-js" */),
  "component---src-pages-explore-index-js": () => import("./../../../src/pages/explore/index.js" /* webpackChunkName: "component---src-pages-explore-index-js" */),
  "component---src-pages-for-investors-index-js": () => import("./../../../src/pages/for-investors/index.js" /* webpackChunkName: "component---src-pages-for-investors-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-main-index-js": () => import("./../../../src/pages/main/index.js" /* webpackChunkName: "component---src-pages-main-index-js" */),
  "component---src-pages-test-index-js": () => import("./../../../src/pages/test/index.js" /* webpackChunkName: "component---src-pages-test-index-js" */),
  "component---src-pages-time-index-js": () => import("./../../../src/pages/time/index.js" /* webpackChunkName: "component---src-pages-time-index-js" */)
}

