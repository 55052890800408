import React, {useEffect, useState} from 'react';
import * as styles from "./styles.module.scss";
import {Link} from "gatsby";
import {scrollToTop} from "../../helpers/ScrollTop";
import {trimUrl} from "../../helpers/trimUrl";

const NavBar = ({hideModal}) => {

    const url = typeof window !== "undefined" ? window.location.pathname : ''
    const [selectedLink, setSelectedLink] = useState(url)

    const handleClick = () => {
        scrollToTop()
    }
    useEffect(() => {
        if (typeof window !== "undefined") {
            setSelectedLink(trimUrl(window.location.pathname))
        }
    }, [url])

    const navLinkArr = [
        {link:"/explore", label:"Explore"},
        {link:"/time", label:"Time"},
        {link:"/events", label:"Events"},
        {link:"/about", label:"About"},
        {link:"/for-investors", label:"For Investors"},
        {link:"/contacts", label:"Contacts"},
    ]

    return (
        <nav onClick={hideModal} className={styles.navBar}>
            {navLinkArr.map(el => <Link className={`${selectedLink === el.link? styles.selectedLink : ''}`} onClick={handleClick} to={el.link}>{el.label}</Link>)}
        </nav>
    );
};

export default NavBar;

