export const scrollToTop = (smooth) => {
    smooth?
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
        :
    window.scrollTo({
        top: 0,
    })
}