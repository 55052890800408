import React, {useEffect, useState} from "react";
import * as styles from './styles.module.scss'


const CookiesModal = () => {
    const [showModal, setShowModal] = useState(true)

    useEffect(() => {
        const getData = window.localStorage.getItem('acceptCookies') || []
        if (getData?.length === 0) {
            setShowModal(false)
        }
    }, [])

    const handleClick = () => {
        if (typeof window !== "undefined") {
            localStorage.setItem('acceptCookies', 'false');
            setShowModal(true);
        }
    }

    return (
        <>
            {showModal ?
                '' :
                <div className={styles.cookies_modal_container}>
                    <p>
                        We use cookies to ensure that we give you the best experience on our website.
                        <br/>
                        If you continue to use this site we will assume that you are happy with it.
                    </p>
                    <div className={styles.cookies_modal_buttons_block}>
                        <a href="https://ar-generation.com/policy.html" target="_blank">Cookies policy</a>
                        <button onClick={handleClick}>Accept Cookies</button>
                    </div>
                </div>
            }
        </>
    )
}
export default CookiesModal
