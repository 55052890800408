import React, {createContext, useContext, useEffect, useReducer} from 'react';
import {combineReducers} from "./combineReducers";
import {reducers} from "./reducers";
import {getEvents} from "../api/getData";
import {SET_EVENTS} from "./events/actionTypes";

const initial = {
    events: []
}
export const MyContext = createContext(initial)

export const useContextState = () => {
    const context = useContext(MyContext);
    if (!context) {
        throw Error("useContext should br used within a CurrencyProvider");
    }
    return context;
}

const StateProvider = ({children}) => {
    const [state, dispatch] = useReducer(combineReducers(reducers), initial)

    const getEventsData = async () => {
        const data = await getEvents()
        dispatch({type: SET_EVENTS, payload: data})
        return data
    }
    useEffect(() => {
        getEventsData()
    }, [])

    return (
        <MyContext.Provider value={{state, dispatch}}>
            {children}
        </MyContext.Provider>
    )
}
export default StateProvider
