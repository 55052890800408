import React from "react";

const Logo = () => {
    return (<svg width="150" height="75" viewBox="0 0 1000 260" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M399.948 95.2113V153.803C399.948 181.006 376.176 196.7 356.583 196.7C338.558 196.7 313.48 182.314 313.48 153.541V95.2113H335.946V153.28C335.946 159.557 338.036 164.527 342.738 168.189C347.179 172.113 352.926 173.682 356.583 173.682C358.673 173.682 361.285 173.159 363.636 172.374C366.249 171.328 368.861 169.759 370.951 167.928C373.041 166.097 374.608 164.004 375.653 161.65C376.959 159.034 377.482 156.419 377.482 153.28V95.2113H399.948Z"
                fill="#0057BD"/>
            <path
                d="M489.551 156.419V158.249V196.439H469.175V191.469V156.942H468.913V156.68C468.913 151.449 466.823 146.479 463.166 142.555C459.509 138.893 454.545 136.801 449.06 136.801C443.835 137.062 438.872 138.893 434.953 142.817C431.296 146.479 429.206 151.449 429.467 156.942H429.206V196.439H407.524V115.875H429.206V126.338C435.737 118.491 441.745 117.183 448.798 116.398C471.003 114.829 489.551 128.954 489.551 156.419Z"
                fill="#0057BD"/>
            <path
                d="M518.025 106.459H496.343V88.1489H517.764V106.459H518.025ZM518.025 196.439H496.343V116.66H518.025V196.439Z"
                fill="#0057BD"/>
            <path
                d="M544.148 193.823C540.752 192.515 537.618 190.684 534.222 187.545C528.736 182.314 525.601 174.99 526.123 166.62V166.358V164.789L543.365 165.05H544.671V166.358C544.671 166.358 544.932 170.282 546.499 172.636C548.328 175.775 552.508 177.083 558.255 177.083C562.957 177.083 566.614 176.559 568.966 175.252C571.578 173.944 571.839 172.374 571.839 171.59C571.839 166.62 562.957 164.789 557.471 164.266C551.463 163.743 546.238 162.435 542.059 160.604C537.879 158.773 534.483 156.68 531.87 153.803C528.213 149.618 526.385 144.91 526.385 139.155C526.385 131.831 529.258 126.6 531.87 123.722C537.356 117.445 546.499 114.044 558.777 114.044C574.974 114.044 583.072 121.107 586.729 127.123C591.17 133.924 591.17 141.509 591.17 142.294V143.602H572.1V142.294V142.032C572.1 141.248 571.839 138.893 570.794 137.062C568.704 134.185 564.263 132.354 558.516 132.354C551.985 132.354 545.455 134.185 545.455 137.847C545.455 139.678 546.499 140.986 548.589 142.294C550.94 143.602 554.598 144.648 559.561 145.171C571.317 146.479 580.199 150.402 585.423 156.419C589.08 160.604 590.909 165.573 590.909 171.066C590.909 173.421 590.909 179.96 585.684 186.237C583.072 189.376 579.415 191.73 574.713 193.3C570.01 194.869 564.525 195.654 558.255 195.654C553.03 196.439 548.328 195.654 544.148 193.823Z"
                fill="#0057BD"/>
            <path
                d="M679.206 156.157C679.467 178.129 661.442 196.177 639.498 196.439C632.184 196.439 624.347 194.346 619.122 187.807V226.258H597.44V116.66H619.122V125.03C623.302 119.537 631.661 116.66 638.976 116.398C661.181 116.137 678.945 134.185 679.206 156.157ZM658.83 156.419C658.83 151.187 656.74 146.217 653.083 142.555C649.425 138.893 644.462 137.062 639.237 137.062C634.013 137.062 629.049 139.155 625.392 142.817C621.735 146.74 619.645 151.449 619.645 156.68C619.906 161.912 621.735 166.881 625.653 170.543C629.31 174.205 634.274 176.298 639.498 176.298C644.723 176.036 649.687 174.205 653.344 170.282C657.001 166.62 659.091 161.65 658.83 156.419Z"
                fill="#0057BD"/>
            <path
                d="M767.764 158.249V196.177H747.649V191.207V156.942H747.388V156.418C747.388 151.187 745.298 146.217 741.641 142.555C737.983 138.893 733.02 136.801 727.795 136.801C722.571 136.801 717.607 138.893 713.95 142.817C710.293 146.479 708.203 151.449 708.464 156.68V156.942H707.942V196.439H686.259V95.4728H707.942V126.6C714.472 118.752 720.219 117.445 727.534 116.921C749.478 115.09 767.764 129.215 767.764 156.68V158.249Z"
                fill="#0057BD"/>
            <path
                d="M855.277 163.742L797.022 163.481C798.067 166.62 799.112 167.928 801.463 170.282C805.381 173.944 810.345 176.036 815.308 176.036C820.533 175.775 825.496 173.944 829.154 170.02C829.415 169.759 829.676 169.497 829.676 169.497L849.791 175.775C841.432 188.592 832.55 195.916 815.308 196.177C793.365 196.439 775.34 178.39 775.078 156.419C774.817 134.447 792.842 116.398 814.786 116.137C836.729 115.875 854.755 133.924 855.016 155.895C855.277 157.203 855.277 162.696 855.277 163.742ZM801.202 142.555C799.373 144.386 798.067 146.479 797.022 149.095H833.333C832.288 146.74 830.982 144.386 828.892 142.294C825.235 138.632 820.272 136.801 815.047 136.801C810.084 136.801 805.12 138.893 801.202 142.555Z"
                fill="#0057BD"/>
            <path
                d="M911.703 115.091V137.324C894.984 137.324 884.796 150.926 884.796 161.127V196.439H863.114V160.865V116.398H884.796V128.431C888.192 118.491 895.507 115.091 911.703 115.091Z"
                fill="#0057BD"/>
            <path
                d="M999.739 163.742L941.484 163.481C942.529 166.62 943.574 167.928 945.925 170.282C949.843 173.944 954.807 176.036 959.77 176.036C964.995 175.775 969.958 173.944 973.616 170.02C973.877 169.759 974.138 169.497 974.138 169.497L994.253 175.775C985.894 188.592 977.012 195.916 959.77 196.177C937.827 196.439 919.802 178.39 919.54 156.419C919.279 134.447 937.304 116.398 959.248 116.137C981.191 115.875 999.216 133.924 999.478 155.895C999.739 157.203 1000 162.696 999.739 163.742ZM945.925 142.555C944.096 144.386 942.79 146.479 941.745 149.095H978.057C977.012 146.74 975.706 144.386 973.616 142.294C969.958 138.632 964.995 136.801 959.77 136.801C954.546 136.801 949.582 138.893 945.925 142.555Z"
                fill="#0057BD"/>
            <path
                d="M259.666 130C259.666 187.807 222.048 236.72 170.063 253.722C181.035 243.783 188.088 229.658 189.394 213.964C215.517 195.392 232.759 164.527 232.759 130C232.759 73.2394 186.52 26.9416 129.833 26.9416C73.1452 27.2032 27.1682 73.2394 27.1682 130C27.1682 166.881 46.7607 199.316 75.7576 217.626C84.117 222.857 93.2602 226.781 102.926 229.396C111.547 231.75 120.69 233.058 130.094 233.058C144.984 233.058 157.262 221.026 157.262 205.855V93.3803H184.431V205.855C184.431 235.151 160.92 259.215 131.923 260C131.4 260 130.878 260 130.355 260C129.833 260 129.31 260 128.788 260C114.943 259.738 101.358 257.384 88.8192 253.199C37.0951 235.936 0 187.284 0 130C0 58.33 58.255 0 129.833 0C201.411 0 259.666 58.33 259.666 130Z"
                fill="#0057BD"/>
            <path d="M102.926 93.3802V221.026C93.2602 218.149 84.117 213.964 75.7576 208.471V93.3802H102.926Z"
                  fill="#0057BD"/>
        </svg>)
}
export default Logo
