import {SET_EVENTS, GET_EVENTS} from "./actionTypes";

export const eventsReducer = (state, action) => {
    switch (action.type) {
        case SET_EVENTS:
            return {...state, events: action.payload}
        default:
            return state
    }
}
